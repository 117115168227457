.App {
  opacity: 0.8;
  a {
    font-family: "Times New Roman", Times, serif;
    color: #777;
    margin-top: 1rem;
    display: inline-block;
  }
  text-align: center;
  header {
    position: relative;
    max-width:650px;
    margin: 0 auto;
    img {
      max-width: 350px;
      width: 100%;
    }
    .sub-title {
      position: relative;
      display: block;
      text-align: center;
      box-sizing: border-box;
      padding: 0 1rem;
      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #000;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 0;
      }
      //left: 0;
      //right: 0;
      font-family: "Times New Roman", Times, serif;
      //border-top: 1px dashed #000;
      
      .block {
        position: relative;
        display: inline-block;
        font-size: 1.2rem;
        padding: 1rem;
        text-transform: uppercase;
        background-color: #fff;
        border: 2px solid #000;
        
      }
      
    }
  }

  .letter {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    max-width: 250px;
    margin: 0 auto;
  }

  footer {
    box-sizing: border-box;
    max-width:650px;
    border-top: 2px solid #000;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 2rem;
    img {
      width: 50%;
      max-width: 200px;
    }
  }
}


.the-keepers {
  padding-top:1rem;
  text-align: center;
  opacity: 0.5;
  span {
    opacity: 0;
    display: block;
  }
  svg {
    margin: 0 auto;
    display: inline-block;
    
  }
  
}

.suits {
  position: relative;
  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
  .suit-overlay {
    div {
      width: 50px;
      height: 50px;
      display: inline-block;
    }
  }
}